.container__testimonial-reusableComponent {
  display: flex;
  width: 80%;
  margin: 2rem auto;
}

.testimonial-reusable {
  flex: 40%;
}

.testimonial-content {
  flex: 60%;
}

.testimonial-slider {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.testimonial-container {
  display: flex;
  transition: transform 0.5s ease;
}

.testimonial {
  flex: 0 0 100%;
  display: none;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.testimonial.active {
  display: flex;
  background-color: rgba(211, 211, 206, 0.254);
  padding: 5rem;
}

.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.testimonial-name {
  margin-bottom: 5px;
}

.testimonial-rate {
  font-style: italic;
  color: #888;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  padding: 10px 17px;
  background-color: rgba(17, 17, 18, 0.311);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.slider-button:hover {
  background-color: #323f73;
  ;
}

.prev-button {
  left: 5px;
}

.next-button {
  right: 5px;
}

@media screen and (max-width: 1200px) {
  .container__testimonial-reusableComponent {
    flex-direction: column;
    align-items: center;
  }
  .testimonial-reusable {
    flex: 100%;
    margin-bottom: 2rem;

  }

  .slider-button {
display: none;
  }
} 






