.container__faq-aside {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.container-faq {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container-faq h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.container-faq div {
  margin-bottom: 20px;
}

.container-faq h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.container-faq p {
  font-size: 14px;
}

@media (max-width: 768px) {
  .container__faq-aside {
    flex-direction: column;
  }

  .container-faq {
    width: 90%;
    margin: 0 auto;
  }
}