.admin-container{
  width: 100vw;
  padding: 10px;
  background-color: #f2f2f2;
}
.admin-page {
  width: 100%;
  margin: 20px;
  margin-top: 5rem;
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.073);
}
.admin-page h2 {
  text-align: start;
  margin-bottom: 30px;
  font-size: 24px;
  color: #00008b;
  font-weight: 600 !important;
}
.form-data-container {
  overflow-x: auto;
  margin-top: 0px;
}
.form-data-table {
  border-collapse: collapse;
  font-size: 14px;
  table-layout: fixed;
}

.form-data-table td:first-child {
  box-shadow: none;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: 300  !important;
}

th {

  background-color: #eaeaea;
  font-weight: bold;
  color: #000044bc;
  font-weight: 500 !important;

}


.form-data-table td:first-child {
  box-shadow: none;
}

th, tr:hover {
  background-color: #f5f5f5;
  background-color: #c0c0c040;
}

button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #00008B;
  color: #fff;
  transition: background-color 0.3s ease;
  max-width: 50px;
}

button:hover {
  background-color: #00008bc2;
}

a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}
.edit {
  margin-left: 15px;
}
.delete{
  background-color: #333;
}
.delete:hover{
  background-color: #0a0909e8 !important;
}
.user-separator {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.form-data {
  padding:10px;
}

.form-data h3 {
  margin-bottom: 7px;
  font-weight: 500;
  color: #586bb3;
}
.form-data-table {
  border-collapse: collapse;
  margin-bottom: 0px;
}

.form-data-table th,
.form-data-table td {
  padding: 8px;
  border: 1px solid #ccc;
}

.actions-container button {
  margin-right: 5px;
}

.actions-container .edit {
  text-decoration: none;
  color: rgb(6, 149, 189);
}
.user-separator{
  display: none;
}

.user-count{
  position: fixed;
  top: 8.5rem;
  right: 2rem;
  background-color: #333333;
  color: #ccc;
  padding: 8px 10px;
  border-radius: 11px;
}
.no-results{
  justify-content: center;
  text-align: center;
  margin-top: 11%;
}
.no-results h3{
  font-size: 23px;
  color: rgb(247, 125, 125);
}
.no-results p{
  font-size: 17px;
}

.form-data-table td:first-child {
  box-shadow: none;
}

@media (max-width: 560px) {
  .edit-form-page{
    width: 100% !important;
  }
  .admin-page {
    width: 100% ;
    margin: 9rem auto;
    }
  .thead {
    display: none;
  }
    .form-data-table {
      width: 100%;
    }
  .form-data-container {
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .form-data-table {
      margin-bottom: 10px;
    }
  h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  th,
  td {
    padding: 10px;
    display: block;
    white-space: normal;
    text-align: left;
    border: solid 1px #ffff !important;
    
  }

  th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
  }

  td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
      margin-bottom: -0.25rem !important;
  }

  button {
    padding: 6px 12px;
    font-size: 17px;
    margin: 7px 0;
  }
    .user-count {
      top: 10rem;
    }
}















