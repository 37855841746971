.container__reusableComponent-news {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
}

.news-page {
  padding: 2rem;
}
.news-page h1{
  margin-bottom: 0.5rem;
}

.news-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.news-article {
  background-color: #f1f1f1;
  padding: 1rem;
  border-radius: 4px;
}

.news-article h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.95rem;
}

.news-article img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.news-article p {
  margin-bottom: 0.5rem;
}

.news-article a {
  display: inline-block;
  color: #fff;
  background-color: #29335c;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.news-article a:hover {
  background-color: #5767aa;
}

.date-time{
  display: flex;
  width: 100%;
}
.news-date{
  text-align: start !important;
  font-size: 19px;
  color: #5767aa;
}
.news-time{
  font-size: 19px;
  margin-left: 1rem;
}
.see-more-button,
.see-less-button {
  padding: 10px 20px;
  color: #29335c;
  border: none;
  border-radius: 4px;
  font-size: 19px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background-color: initial;
}

.see-less-button:hover {
  cursor: pointer;
}

.see-more-button:hover,
.see-less-button:hover {
  color: #5767aa;
  background-color: #cccdd2;
}

@media only screen and (max-width: 1024px) {
  .container__reusableComponent-news {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .news-container {
    grid-template-columns: 1fr;
  }
}
