.container {
  max-width: 600px;
  margin: 3.5rem auto;
  padding-left: 30px !important;
  padding-right: 10px !important;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
}

.first__lastName-container,
.container_name {
  display: flex;
  justify-content: space-around;
}

#firstName {
  margin-right: 15px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-weight: 300 !important;
}

input,
textarea,
select {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  flex: 1;
  margin-top: -7px;
  width: 95% !important;

}

button[type='submit'],
button[type='button'] {
  margin-top: 10px;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  background-color: #333 !important;
}

button[type='submit']:hover,
button[type='button']:hover {
  background-color: #555;
  ;
}

.container {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

h2 {
  font-size: 24px;
  margin-bottom: 30px;
}

input,
textarea,
select {
  width: 100%;
  font-size: 16px;
}

label {
  font-size: 16px;
  display: flex;
  align-items: center;
}

textarea,
button {
  max-width: 95% !important;
}

input,
textarea {
  max-width: 95% !important;
}

input,
textarea:focus {
  outline: none;
}

button[type='submit'],
button[type='button'] {
  width: auto;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
}

.container__back-nextButton {
  display: flex;
  justify-content: space-between;
}

.submit-btn {
  margin-left: 15px;
  margin-right: 15px;
}

.cancel-btn {
  background-color: #121111c0 !important;
  color: #ffffff !important;
}

.cancel-btn:hover {
  background-color: #0707079a !important;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.back-button,
.next-btn {
  width: 100px !important;
}

@media (max-width: 560px) {
  .container {
    width: 90%;
  }

  .container__back-nextButton {
    display: inline-block;
  }

  .back-button,
  .next-btn {
    width: 100% !important;
  }

  button {
    width: 100% !important;
  }

  .submit-btn {
    margin: 0;
  }
}