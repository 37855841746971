.footer {
  padding: 40px 0;
  background-color: #232c4f;
  margin-top: 7rem;
}
.container-subfooter{
  width: 100%;
  border-top: solid #f2f2f2 0.5px;
  justify-content: center;
  text-align: center;
  height: 1rem;
}
.subfooter{
  width: 80%;
  color: #f2f2f2;
  text-align: start;
  padding: 1rem;
  margin: 0 auto;
}
.container-footer {
  max-width: 1200px;
    width: 90%;
  margin: 0 auto;
/* background-color: rgba(243, 241, 241, 0.883); */
  padding: 2rem;
  color: #f2f2f2;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-md-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

h3 {
  margin-bottom: 20px;
  font-size: 20px;
}

p {
  margin-bottom: 10px;
}

.social-icons a {
  color: #555;
  margin-right: 10px;
  font-size: 24px;
}

@media screen and (max-width: 1200px){
  .footer {
      margin-top: 12rem;
    }    
}

@media screen and (max-width: 1024px) {
  .footer {
    margin-top: 15rem;
  }
}
@media screen and (max-width: 768px) {
  .footer {
      margin-top: 5rem !important;
    }
  #footer-component{
    margin-top: 0;
    
  }
  /* .footer {
      margin-top: 36rem;
    } */
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

}

@media screen and (max-width: 300px) {
  .footer {
    margin-top: 40rem;
  }
}