.email-sent {
  position: absolute;
  top: 0.5%;
  left: 27%;
  width: 35%;
  background-color: rgb(236, 96, 96);
  color: white;
  padding: 5px;
  text-align: center;
  animation: fadeOut 3s ease forwards;
  margin: 0 auto;

}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.contact-page {
  margin: 3rem auto;
  max-width: 800px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.contact-page h1 {
  text-align: start;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
}

.contact-form {
  flex-basis: 50%;
  margin-bottom: 20px;
}

.contact-info {
  flex-basis: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}
.contact-info h2{
  text-align: start;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type='submit'] {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.map-container {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .email-sent{
    width: 90%;
   left: 5%;
  }
  .contact-content {
    flex-direction: column;
  }
  .contact-form,
  .contact-info {
    flex-basis: 100%;
    
  }
}