 .go__back-home{
  background-color: #232c4ff3;
  padding: 0.75rem;
  border-radius: 15px;
  color: #f1f1f1c9;
 }
 .go__back-home:hover{
    color: #ffff;
  background-color: #2e3a6a;
 }
 .search-container {
   display: flex;
   justify-content: start;
   align-items: center;
   position: fixed;
   top: 0;
   left: 0;
   padding: 15px;
   box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

 }
 .search-item {
   display: flex;
   align-items: center;
   text-align: center;
   background-color: #fff;
   border-radius: 20px;
   box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
   width: 40vw;
   overflow: hidden;
   margin: 0 10px;

 }

 .search-icon {
   font-size: 21px;
   margin-right: 10px;
 }

 .search-input[type="text"] {
   width: 450px;
   height: 5px;
   padding: 15px;
   margin-top: 10px;
   border: none;
   font-size: 16px;
 }

 .search-input[type="text"]:focus {
   outline: none;
 }

 @media (max-width: 768px) {
   .search-container {
     width: 99%;
     top: 0;
     background-color: #fff;
     padding-bottom: 11px;
   }

   .search-item {
     margin: 10px 0;
   }

   .search-container {
     display: block;
   }

 }