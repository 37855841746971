.container-home {
  background-color: #f2f2f2;
}

.main-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: #CCCCCC;
  background-color: #161c33;
}

.main-content {
  max-width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #232c4f;
  margin: 2rem 0;
}

.main-image {
  width: 100%;
  flex: 1;
  margin: 10px;
  text-align: center;
  margin: 0 auto;
}

.main-image img {
  width: 100%;
  height: auto;
}

.slide {
  height: 100%;
  padding: 20px;
  color: white;
  text-align: center;
}

.slide-1 {
  color: #000000b4;
  background-color: #f1f1f1;
}

.slide-2 {
  background-color: azure;
  color: #333;
}

.slide.active {
  border: 2px solid white;
}

.get__free-quote {
  flex-wrap: wrap;
  text-align: center;
  padding: 1rem;
  margin-top: 0;
  margin-left: 1.5rem;
  border: #5767aa solid 0.5px;
  border-radius: 15px;
}

.get__free-quote button {
  background-color: #5767aa;
  padding: 9px 20px 13px 25px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.get__free-quote button:hover {
  background-color: #192245;
}
.quote-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #5767aa;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.quote-button:hover {
  background-color: #323f73;
  color: rgb(207, 201, 201);
}

.container-slider {
  flex: 1;
  max-width: 300px;
  margin: 1rem auto;
  padding: 2rem !important;
  border-radius: 15px;
  background-color: #f2f2f2;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.slide {
  padding: 1rem;
  margin: 0.5rem auto;
}

.main-text-slider h2 {
  color: #333333d1;
  text-align: start;
}

.main-text-slider p {
  text-align: start;
}

.main-text-slider {
  text-align: center;
  margin-bottom: 20px;
}

.slider-controls {
  text-align: center;
}

.prev-btn,
.next-btn {
  margin: 0 10px;
  cursor: pointer;
  color: #5767aa;
}

.container__service-card {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}

.cards {
  width: calc(33.33% - 20px);
  margin: 0 auto;
  padding: 10px;
  background-color: #232c4f;
  border: #232c4f solid 2px;
  transition: all 0.3s ease-in-out;
  border-radius: 7px;
  margin-bottom: 2rem;
}

.cards:hover {
  background-color: #161c33;
  cursor: pointer;
}

.card-title {
  color: #f2f2f2;
}

.card-detail {
  color: #f1f1f1;
  text-align: start;
  margin-left: 10px;
  margin-top: -7px;
}

.card-symbol {
  width: 40px;
  height: 40px;
  background-color: #767b8d61;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  padding: 10px;
}

.card__title-symbol {
  display: flex;
  justify-content: start;
  text-align: start;
  align-items: start;
  gap: 0.5rem;
}

.card-button {
  display: block;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 7rem;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  background-color: #5767aa;
  color: #f1f1f1;

}

.card-button:hover {
  background-color: #323f73;
  color: #7b7878d7;
}

/* Services */
.container__aside-service {
  width: 100vw;
  background-color: #fefefe03;
  padding: 3rem 0;
  width: 100%;
  margin: -3rem auto;
}

.services {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.content__aside-services {
  margin: 0 auto;
  width: 37%;
  border-radius: 7px;
  margin-right: 1rem;
  color: #fff;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.5) 0px 0.25em 1em;
}

.content__aside-services h1 {
  color: #333333cd;
  height: 50px;
}

/* Testimonial */
.testimonials {
  width: 59%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.testimonial-card {
  display: flex;
  justify-content: space-around !important;
  margin-right: 1rem;
  margin-left: 2rem;
  text-align: start;
  padding: 2rem;
  height: auto;
  background-color: #d4d4d4;
  min-height: 200px;

}

.testimonial__name-rate {
  display: flex;
  justify-content: space-between;
}

.testimonial-name {
  font-weight: bold;
}

.testimonial-rating {
  color: rgb(236, 126, 48);
}

.services .service__title-symbol {
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
}

.service__title-symbol {
  padding: 0 1rem;
  background-color: #d4d4d4;
  margin-bottom: 5px !important;
  cursor: pointer;
  color: #333;
  color: #f2f2f2;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem !important;
  transition: all 0.35s ease-in-out;
}

.service__title-symbol:hover {
  background-color: #f2f2f2;
}

.service-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #333333d7;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.service-symbol {
  font-size: 24px;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 7px;
  color: #161c33;
  margin-top: -10px;
  background-color: #ffff;
  justify-content: center;
  text-align: center;
}

.home__news-testimonial {
  margin-left: 6rem;
  margin-bottom: 0.5rem;
  margin-top: -2.5rem;
  text-align: start;
}

.container__home-news {
  width: 100%;
}

.container__home-news h1 {
  margin-top: 2rem;
}

.second__container-news {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 54%;
  margin: 0 auto;
  margin-left: 3rem;
  margin-top: -22rem;
}

.content__image-news img {
  width: 100%;
}

.news {
  width: calc(50% - 3rem);
  background-color: rgba(209, 207, 207, 0.883);
  padding: 2rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  margin-left: 3rem;
  margin-top: 4rem;
}


/* Responsive Adjustments */
@media (max-width:1280px) {
  .second__container-news {
    margin-top: -16rem;
    margin-left: 2.5rem
  }

  .content__aside-services {
    margin-top: -4rem;
  }
}

@media screen and (max-width: 1024px) {

  .main-content {
    background-color: initial;
    margin-top: 1rem !important;
  }

  .second__container-news {
    margin-top: 7rem;
    margin-left: 1;
  }

  .get__free-quote {
    border: none;
    width: 100%;
    margin: 1rem auto 0;
  }

  .get__free-quote button {
    width: 100%;
  }

  .container-slider {
    width: 100%;
    margin: 2rem auto;
    padding: 1rem;
    height: 16rem;
  }

  .second__container-news {
    width: 90% !important;
    margin: 2rem auto !important;
  }

  .news {
    width: 100%;
  }

  .second__container-news {
    width: 70%;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .news {
    width: calc(50% - 2rem);
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 964px) {
  .main-content {
    width: 100%;
    margin: 0 auto;
  }

  .container-slider {
    height: 14rem;
  }

  .container__service-card {
    margin: 0 auto;
    padding: 1rem 0;
    margin-top: -2.5rem;
  }

  .cards {
    width: calc(50% - 20px);
  }

  .main-content {
    display: block;
    width: 100%;
  }

  .main-image {
    max-width: 100%;
  }

  .container-slider {
    max-width: 100%;
  }

  .main-container {
    display: inline-block;
  }

  /*Service & Testimonial*/
  .container__aside-service {
    width: 100%;
  }

  .services .service__title-symbol {
    justify-content: start;
  }

  .services {
    width: 100%;
    justify-content: center;
  }

  .content__aside-services {
    width: 80%;
    margin: 0 auto;
    margin-top: 3.5rem;
  }

  .testimonials {
    width: 85%;
    display: block;
    margin-top: -3.5rem;
    margin-left: -1rem;
  }

  .testimonial-card {
    margin-top: 3.5rem;
  }

  .services .service__title-symbol {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .second__container-news {
    width: 80%;
    margin-left: 1rem;
    margin-right: 1rem;
    margin: 0 auto;
  }

  .news {
    width: 90%;
    margin-left: 0;
    margin-right: 0;
    margin: 0 auto;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .slide {
    padding: 0 5px;
    margin: 0.5rem auto;
  }

  .cards {
    width: calc(100% - 20px);
  }
}