.container-about {
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.about-content {
  display: flex;
  padding: 3rem 1.5rem;
  gap: 2rem;
  width: 100% !important;
  margin: 0 auto;
}

.about-details {
  max-width: 60%;
}

.about-details p {
  margin-top: 10px;
  font-size: 17px;
}

.about__aside-details {
  max-width: 100%;
  background-color: #232c4f;
  padding: 2rem 1rem;
  border-radius: 7px;
  color: #f2f2f2;
  text-align: center;
}

.about__aside-details h2 {
  color: #f1f1f1;
  text-align: center;
}

.about__aside-details button {
  display: block;
  margin: 0 auto;
  background-color: #5767aa;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .container-about {
    width: 100%;
  }

  .about__aside-details {
    margin-top: -2rem;
  }

  .about-content {
    flex-wrap: wrap;
    width: 100%;
  }

  .about-details {
    flex: 1 1 100%;
    max-width: 100% !important;
    margin-bottom: 2rem;

  }

  .about__aside-details {
    width: 100%;
    max-width: 100%;

  }
}