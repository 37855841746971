

 .aboutUs__image-asideContent {
  margin: 0 auto;
}

.aboutUs__image-asideContent img {
  position: relative;
  display: inline-block;
  width: 85%;
  height: 450px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto ;
  margin-top: 3rem;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
}

.aboutUs__image-asideContent img:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.about__us-title{
  position: absolute;
  font-size: 31px;
  font-weight: 400;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #323f73;
  background-color: #ffff;
  padding: 0.25rem 2rem;
}
.content-component{
  display: flex;
  margin: 0 auto;
  width: 90%;
  margin: 3rem auto;
}
.content-paragraphes{
  width:120%;
  height: 100%;
  text-align: start;
  margin: 0 auto;
  margin-left: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.25rem !important;
}
/*See more text and less text by read more and less button */
.read-more-button,
.read-less-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.read-more-button:hover,
.read-less-button:hover {
  background-color: #ddd;
}

.button-icon {
  margin-right: 8px;
}

@media (max-width: 964px){
  .about__us-title {
    width: 70%;
    left: 50%;
    top: 35% !important;
      font-size:20px;
      margin: 0 auto;
      text-align: center;
      justify-content: center;
  }
.about__aside-details {
    flex-direction: column;
  }
  .content-paragraphes {
    text-align: start;
    margin: 0 auto;
    width: 90%;
  }
  .aboutUs__image-asideContent img{
    width: 80%;
    height: 300px;
  }
  .content-component{
    width: 90%;
    display: flex;
    justify-content: center !important;
    margin: 2rem auto !important;
  }
}


@media (max-width: 768px) {
.content-paragraphes{
  margin-top: 2rem;
}
  .aboutUs__image-asideContent {
    text-align: left;
  }
  .content-component{
    flex-direction: column;
  }
}

 @media (max-width: 768px) {
   .aboutUs__image-asideContent {
     text-align: center;
   }

   .about__us-title {
     top: 40%;
   }

   .content-component {
     margin: 2rem auto;
     margin-right: 0;
   }
 }

