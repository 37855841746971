.container__top-nav {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 25px;
}
.logo {
  display: flex;
  justify-content: center;
  text-align: center;
}
.logo img{
  position: absolute;
  width: 4rem;
  height: 3rem;
}
.container__top-nav p,
.container__top-nav div {
  margin: 0 auto;
  font-size: 19px;
  text-align: start;
  position: relative;
}

@media (max-width:760px) {
.container__top-nav{
  display: none;
}

}