
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  /* height: 100vh; */
  /* background-color: #f2f2f2; */
}

.login-form {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.login-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.login-form input {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.login-form input:focus {
  outline: none;
}

.login-form button {
  width: 23%;
  padding: 9px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #555;
}

@media (max-width: 765px) {
  .login-form button {
    width: 23% !important;
  }
}

/* Additional styles for responsiveness */
@media (max-width: 480px) {
  .login-form {
    width: 80%;
  }

  .login-form h2 {
    font-size: 20px;
  }

  .login-form input,
  .login-form button {
    width: 100%;
  }
}