.container-about {
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.about-content {
  display: flex;
  width: 100% !important;
  padding: 3rem 1.5rem;
  gap: 2rem;
  margin: 0 auto;
}

.about-details {
  max-width: 60%;
}
.about-details h1{
  text-align: start;
}

.about-details p {
  margin-top: 10px;
  font-size: 17px;
}

.about__aside-details {
  max-width: 100%;
  background-color: #232c4f;
  padding: 2rem 1rem;
  border-radius: 7px;
  color: #f2f2f2;
  text-align: center;
}

.about__aside-details h2 {
  color: #f1f1f1;
  text-align: center;
}

.about__aside-details button {
  display: block;
  margin: 0 auto;
  background-color: #5767aa;
  font-size: 16px;
}
.call-button{
  margin-top: 1rem !important;
  padding: 0.5rem;
}

.call-button a{
  color: #f1f1f1;
}

.Container-asideDetails__asideService {
  display: block;
}

.about__aside-services {
  margin: 2rem 0;
}

.about__aside-services h1{
  margin-bottom: -0.5rem;
}

.about__aside-services {
  display: block;
  justify-content: center;
}

.container-about img {
  width: 100%;
  height: auto;
  margin: 2rem 0;
}

.icon-title {
  display: flex;
  justify-content: start;
  text-align: start;
  background-color: #5a585834;
  margin-top: 0.5rem;
  padding: 0 5px;
  color: #333333cd;
  transition: all 0.3s ease-in-out;
}

.icon-title:hover {
  background-color: #fcfcfc;
  cursor: pointer;
  box-shadow: none;
}

.about__icon-service {
  width: 60px;
  height: 60px;
  font-size: 20px !important;
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #121212;
}

.about__title-service {
  display: flex;
  text-align: center;
  padding-top: 0.65rem;
  color: #333333de;
}

.about__title-service a {
  color: #121212;
}

.active{
  background-color: #ffff;
}

/*Media Query*/
@media screen and (max-width: 768) {
  .about__aside-details {
    margin-top: -2rem;
  }

  .about-content {
    flex-wrap: wrap;
  }

  .about-details {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .about__aside-details {
    width: 100%;
    max-width: 100%;
  }
}