
.edit-form-page {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
}

.updated-date__show{
  margin-left: 3.5rem;
}
.container-blogs{
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  justify-content: space-around;


      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
      border-radius: 10px;
      padding: 20px;
}
.blog-right , .blog-left{
  width: 50%;
}
h2 {
  font-size: 23px;
  margin-bottom: 20px;
    color: #333;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 8px;
  font-weight: 600 !important;
}

input {
  padding: 5px;
  margin-bottom: 11px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
}

a {
  color: #007bff;
  text-decoration: none;
  margin-top: 8px;
}
.cancel{
  margin-left: 15px;
}

@media (max-width: 975px) {
  .container-blogs {
    flex-direction: column;
  }
  .blog-right,
  .blog-left {
    width: 100%;
  }
    .updated-date__show {
      margin-left: 0;
    }
        .updated-date__show {
          margin-left: 1.5rem;
        }
}

@media (max-width: 480px) {
  .container-blogs{
    flex-direction: column;
  }
  .blog-right,
  .blog-left{
    width: 100%;
  }
  .container {
    padding: 10px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  input {
    padding: 6px;
    margin-bottom: 12px;
    font-size: 12px;
  }

  button[type="submit"] {
    padding: 8px 12px;
    font-size: 14px;
  }

  a {
    font-size: 14px;
  }
}