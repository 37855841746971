@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
}

h3.logo {
  margin-right: auto;
}

nav a:hover {
  color: #ffff;
}
nav{ 
  padding-bottom: 0.5rem;
}

nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--textColor);
  text-decoration: none;
  line-height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 90px;
  transition: all 0.3s ease-in-out;
}

nav a:hover {
  color: #ffff;
}

nav a.active {
  background-color: #3c4d91;
  border-radius: 4px;
} 

header .nav-btn {
  padding: 0px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  margin: 0 auto;
  text-align: end;
}

header .nav-btn:hover {
  background-color: var(--mainColor);
}

header div,
nav {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

header {
  position: sticky;
  top: 0 !important;
  z-index: 100;
}

.navbar {
  background-color: var(--mainColorLight) !important;
  transition: background-color 0.3s ease-in-out;
}

.navbar.top {
  background-color: var(--mainColor) !important;
}
.responsive_nav a {
  align-items: start;
}

.has-submenu {
  position: relative;
}

.submenu {
  position: absolute;
  width: 15rem;
  top: 100%;
  left: 0;
  display: none;
  background-color: #2c3866 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.has-submenu:hover .submenu {
  display: block;
  transition: all 0.3s ease-in-out;
}

.submenu a {
  display: block;
  color: #f2f2f2;
  text-decoration: none;
  width: 100%;
  justify-content: start;
  text-align: start;
  align-items: center;
  margin: 0 auto;
}

.submenu a:hover {
  background-color: #5767aa;
  color: #f2f2f2;
}


/*Media query*/
@media only screen and (max-width: 764px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: var(--mainColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

    header .responsive_nav a{
      text-align: start !important;
    }
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: var(--mainColor) !important;
  }

  nav a {
    font-size: 1.35rem;
  }

  /*Submenu*/
  .submenu {
    width: 20rem;
    left: 2rem;
    background-color: var(--mainColorLight);
    font-size: 17px;
  }

  .submenu a {
    width: 100%;
    text-align: start;
    margin: 5px auto;
  }
  .submenu a:hover {
    background-color: var(--mainColor);
  }
}